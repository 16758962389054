export const SESSION_ACTION_BUTTON_ADDNOTE_FRAGMENT = `
  fragment sessionActionButtonAddnoteFragment on Session {
    __typename
    id
    uid
    name
    imageFileResource {
      schemaCode
      path
    }
  }
`;
